import { Link, useLocation } from "react-router-dom";
import backIcon from "@/assets/back.png";
import "./ColorMap.css";
import RandomImage from "@/components/RandomImage/RandomImage";
import { useState } from "react";
import TerrainSize from "@/components/TerrainSize/TerrainSize";
import TextToImage from "@/components/TextToImage/TextToImage";


const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

function ColorMap() {
    const [imgs, setImgs] = useState(['/image/example1.png', '/image/example1.png', '/image/example1.png', '/image/example1.png']);
    const location = useLocation();
    const param = location.state;
    console.log(param);

    const getRandomImg = async () => {
        await delay(5000);
        setImgs(['/image/example2.png', '/image/example2.png', '/image/example2.png', '/image/example2.png'])
    }

    return (
        <div>
            <section>
                <div className=" flex items-center">
                    <Link to="/home/scene-choice/method" className="flex items-center cursor-pointer">
                        <img src={backIcon} alt="back" width={14} height={14} />
                        <span className="ml-2 text-link">Back</span>
                    </Link>
                    <h2 className=" ml-10">Generate 3D town scene</h2>
                </div>
            </section>
            <section className=" mt-10 space-y-4 w-[54rem]">
                <TextToImage
                    serialNumber={1}
                />

                <RandomImage
                    images={imgs}
                    isDisable={false}
                    callback={getRandomImg}
                    serialNumber={2}
                />

                <TerrainSize
                    isDisable={false}
                    callback={() => { }}
                    serialNumber={3}
                />
            </section>
        </div>
    );
};
export default ColorMap;