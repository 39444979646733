import { useState } from "react";
import "./RandomImage.css";
import { RandomImageProps } from "@/types";


function RandomImage({ images, serialNumber, isDisable, callback }: RandomImageProps) {
    const [activeBtn, setActiveBtn] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const changeBtnActive = (index: number) => {
        setActiveBtn(index);
    };

    const randomHandler = async () => {
        setLoading(true);
        setDisabled(true);
        if (callback) {
            await callback();
        }
        setDisabled(false);
        setLoading(false);
    }

    return (
        <div className="random p-5 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Generate your environment with Text</h1>
            </div>
            <h3>Choose a map from library</h3>
            <div className="flex justify-between w-full">
                <section className="flex img-container">
                    {
                        images.length && images.map((url, idx) => {
                            return (
                                <button
                                    disabled={isDisable || disabled}
                                    key={url + idx}
                                    className={`mr-3 w-[5rem] h-[5rem] rounded-lg ${idx === activeBtn ? "active" : ""} ${!isDisable && !disabled ? "cursor-pointer" : " cursor-not-allowed"}`}
                                    onClick={() => changeBtnActive(idx)}>
                                    <img src={url} alt="" />
                                </button>
                            )
                        })
                    }
                </section>
                <section className="fex-1 flex items-end">
                    <button
                        className={`random-btn ${!isDisable && !disabled ? "hover:bg-[rgba(255,255,255,0.2)] cursor-pointer" : " cursor-not-allowed"}`}
                        disabled={isDisable || disabled}
                        onClick={randomHandler}
                    >{loading ? "loading..." : "Random"}</button>
                </section>
            </div>
        </div>
    );
}
export default RandomImage;