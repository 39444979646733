import { TerrainSizeProps } from "@/types";
import "./TerrainSize.css";
import { useState } from "react";

function TerrainSize({isDisable,serialNumber,callback}: TerrainSizeProps){
    const [active, setActive] = useState<number>(500);
    const clickHandler = (size: number) => {
        if (isDisable) return
        setActive(size);
        callback()
    }

    return (
        <div className="bg p-5 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Adjust your terrain</h1>
            </div>
            <h3>Basic</h3>
            <div className="flex space-x-4">
                <div 
                className={`terrain-size ${isDisable ? "cursor-not-allowed" : "cursor-pointer"} ${active === 500 ? "active" : ""}`} 
                onClick={ () => clickHandler(500)}>
                    <div className=" w-7 h-7 mr-2"></div>
                    <span>500 * 500</span>
                </div>
                <div className={`terrain-size ${isDisable ? "cursor-not-allowed" : "cursor-pointer"} ${active === 1000 ? "active" : ""}`} onClick={ () => clickHandler(1000)}>
                    <div className=" w-10 h-10 mr-2"></div>
                    <span>1000 * 1000</span>
                </div>
            </div>
        </div>
    );
}
export default TerrainSize;