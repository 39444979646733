import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home/Home.tsx";
import AssetsLibrary from "./pages/AssetsLibrary/AssetsLibrary.tsx";
import Collections from "./pages/Collections/Collections.tsx";
import Marketplace from "./pages/Marketplace/Marketplace.tsx";
import MyOrders from "./pages/MyOrders/MyOrders.tsx";
import MyScenes from './pages/MyScenes/MyScenes.tsx';
import Agreement from "./pages/UserAgreement/Agreement.tsx";
import Login from './pages/Login/Login.tsx';
import Upload from './pages/Upload/Upload.tsx';
import SceneChoice from './pages/SceneChoice/SceneChoice.tsx';
import Layout from './components/Layout/Layout.tsx';
import GenerationMethod from './pages/GenerationMethod/GenerationMethod.tsx';
import ColorMap from './pages/ColorMap/ColorMap.tsx';
import Mapgen from './pages/Mapgen/Mapgen.tsx';


export default function App() {
  return (
    <main className="h-screen flex flex-col">
      <Router>
      <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home/scene-choice" element={<SceneChoice />} />
            <Route path="/home/scene-choice/method" element={<GenerationMethod />} />
            <Route path="/home/color-map" element={<ColorMap />} />
            <Route path="/home/map-gen" element={<Mapgen />} />
            <Route path="/assetslibrary" element={<AssetsLibrary />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/myorders" element={<MyOrders />} />
            <Route path="/myscenes" element={<MyScenes />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </Router>
    </main>
  );
}
