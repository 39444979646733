import "./Login.css";

function Login() {
    return (
        <div className="w-full h-full flex justify-center items-center text-white">
            Login
        </div>
    )
}

export default Login;