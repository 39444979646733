import { Link } from "react-router-dom";
import backIcon from "@/assets/back.png";
import "./Mapgen.css";

function Mapgen(){
    return (
        <div>
            <section>
                <div className=" flex items-center">
                <Link to="/home/scene-choice" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className=" ml-10">Generate 3D town scene</h2>
                </div>
            </section>
        </div>
    );
}
export default Mapgen;