import { TextToImageProps } from "@/types";
import "./TextToImage.css";
import { ChangeEvent, useState } from "react";

import exampleIcon from "@/assets/text-to-image-example.png";


function TextToImage({ serialNumber }: TextToImageProps) {
    const [text, setText] = useState("A village next to water and mountain");

    const clickTextHandler = (text: string) => {
        setText(text);
    }

    const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }

    return (
        <div className="text-to-image-bg p-5 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Generate your environment with Text</h1>
            </div>
            <div>
                <div className="flex flex-col text-area-cotainer">
                    <textarea value={text} name="" id="" onChange={changeHandler}></textarea>
                    <span className=" text-white opacity-30 mb-2">Some examples for quick start: </span>
                    <div className="flex justify-between">
                        <div className="example-text">
                            <button className=" mr-4" onClick={() => clickTextHandler("An island with forest")}>An island with forest</button>
                            <button onClick={() => clickTextHandler("A town with fields and next to a river")}>A town with fields and next to a river</button>
                        </div>
                        <div className="generate">
                            <button>Generate Map</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <img src={exampleIcon} alt="" width={120} height={120} />
                    </div>
                    <div>
                        <span>regenerate</span>
                        <img src="" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TextToImage;