import React from 'react';
import Navbar from '../Navbar/Navbar';
import "./Layout.css";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const noNavPaths = ['/agreement', '/login'];
  return (
    <div className="flex h-screen">
      {
        !noNavPaths.includes(location.pathname) &&
        <div className="text-white flex-shrink-0">
          <Navbar />
        </div>
      }
      <div className="flex-grow flex-1 overflow-y-auto text-white main">
        <main className="p-6 h-screen">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
