import { useState } from 'react';
import { Link } from 'react-router-dom';
import logoIcon from "@/assets/user_logo.png";
import memberIcon from "@/assets/member.png";
import homeIcon from "@/assets/home.png";
import assetsIcon from "@/assets/assets.png";
import collectionsIcon from "@/assets/collections.png";
import marketPlaceIcon from "@/assets/market-place.png";
import uploadIcon from "@/assets/upload.png";
import ordersIcon from "@/assets/orders.png";
import scenesIcon from "@/assets/scenes.png";
import closeIcon from "@/assets/close.png";
import openIcon from "@/assets/open.png";

import "./Navbar.css";

const Navbar: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleNavbar = () => {
        setIsCollapsed(prevState => !prevState);
    };

    return (
        <nav className={`navbar flex flex-col p-6 text-white text-base h-screen duration-300 ${isCollapsed ? 'w-20' : 'w-[14rem]'}`}>
            <section className='flex items-center mb-10'>
                <img src={logoIcon} alt="user-logo" width={24} height={24} className='mr-1' />
                {!isCollapsed && <div className='flex items-center'>
                    <span>Cybever_admin</span>
                    <img src={memberIcon} alt="member" width={12} height={12} className='ml-2' />
                </div>
                }
            </section>
            <section className='flex-1 flex flex-col justify-between'>
                <ul className="navbar-item-container">
                    <li>
                        <Link to="/" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={homeIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Home</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/myscenes" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={scenesIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>My Scenes</span>}
                        </Link>
                    </li>
                    <li className='line'></li>
                    <li>
                        <Link to="/marketplace" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={marketPlaceIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Marketplace</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/collections" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={collectionsIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Collections</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/assetslibrary" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={assetsIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Assets Library</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/upload" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={uploadIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Upload & Review</span>}
                        </Link>
                    </li>
                </ul>
                <div>
                    <p className='order'>
                        <Link to="/myorders" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={ordersIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>My Orders</span>}
                        </Link>
                    </p>
                    <button onClick={toggleNavbar} className='my-10'>
                        {isCollapsed && <img src={openIcon} alt="collapseIcon" width={24} height={24} />}
                        {!isCollapsed && <img src={closeIcon} className=' ml-32' alt="collapseIcon" width={24} height={24} />}
                    </button>
                </div>
            </section>
        </nav>
    );
};

export default Navbar;
