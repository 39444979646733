import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getTaskList } from '@/service/taskService';
import { Task } from "@/types";
import { reverseSort } from "@/lib/utils";
import { Skeleton, message } from 'antd';
import { AxiosResponse } from 'axios';
import TaskCard from '@/components/TaskCard/TaskCard';

import "./Home.css";
import addIcon from "@/assets/add.png";

function Home() {
    
    const [tasks, setTasks] = useState<Array<Task>>([]);

    const [showModal, setShowModal] = useState(0);

    useEffect(() => {
        getTasks();
    }, []);

    const getTasks = async () => {
        try {
            let response: AxiosResponse = await getTaskList();
            let sortTasks: Array<Task> = reverseSort(response.data.tasks);
            const firstThree = sortTasks.slice(0, 3);
            setTasks(firstThree);

        } catch (error: any) {
            if (error.status === 403) {
                setShowModal(showModal + 1);
            } else {
                message.error("Failed to get the task list or failed to get the village coordinates.");
            }
        }
    }


    return (
        <section>
            <div className="flex justify-between mb-4">
                <span>Home</span>
                {/* <input type="text" /> */}
            </div>
            <div className='flex space-x-6'>
                <Link to="/home/scene-choice"
                className="flex flex-col justify-center items-center w-[17rem] h-[17rem] rounded-md create cursor-pointer">
                    <img src={addIcon} alt="" width={24} height={24} />
                    <span className=" text-lg">Create new scene</span>
                </Link>
                {
                        tasks.length ? tasks.map(task => (<TaskCard
                            key={task.task_id}
                            status={task.status}
                            taskID={task.task_id}
                            imgSrc={import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + task.task_id + "/mini_map.png"}
                            viewClick={() => {}}
                            deleteClick={() => {}}
                            coord={task.coord}
                            pageType='home'
                        />)) : Array.from({ length: 3 }).map((_, i) => (
                            <div className='bg-white rounded-md' key={i}>
                                <Skeleton.Image active={true} style={{ width: "256px", height: "274px" }} />
                            </div>
                        ))
                    }
            </div>
        </section>
    );
};

export default Home;