import { Link } from "react-router-dom";
import "./SceneChoice.css";

function SceneChoice() {
    

    return (
        <div className=" flex flex-col h-full">
            <p>Generate a new scene</p>
            <div className="flex-1 flex justify-center items-center">
                <div>
                    <h2 className=" text-4xl">How would you like to generate your scene?</h2>
                    <span className=" mt-8">Choose one to continue</span>
                    <div className="flex mt-16 text-sm text-center">
                        <Link to="/home/scene-choice/method" className="choice-scene" >
                            <img src="/image/create-scene-4-colors.png" alt="create-scene-4-colors" width={240} height={240}/>
                            <h3 className=" text-xl my-2">2D map</h3>
                            <span>Use 4 colors to draw your terrain</span>
                        </Link>
                        <Link to="/home/map-gen" className="choice-scene ml-10">
                            <img src="/image/create-scene-parameters.png" alt="create-scene-parameters" width={240} height={240}/>
                            <h3 className=" text-xl my-2">3D map</h3>
                            <span>Generate your terrain with parameters</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SceneChoice;